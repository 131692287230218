<template>
  <el-tabs
          v-model="activeName"
          type="border-card"
  >
    <el-tab-pane
            label="登录日志"
            :lazy="true"
            name="1"
    >
      <LoginLog v-if="activeName==1" />
    </el-tab-pane>
    <el-tab-pane
            label="操作日志"
            :lazy="true"
            name="2"
    >
      <OperateLog v-if="activeName==2" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import LoginLog from '@/views/system/log/LoginLog'
import OperateLog from '@/views/system/log/OperateLog'

export default {
  components: { LoginLog, OperateLog },
  data() {
    return {
      activeName: '1',
      loading: false,
    }
  },
}
</script>

<style scoped>

</style>
