<template>
  <div>
    <b-card>
      <div class="filter-wrap flex-between">
        <SearchFilter @handleFilter="handleFilter" :search.sync="listQuery.SearchStr" />
      </div>
      <el-table
        v-loading="loading"
        border
        :max-height="pageH - 140"
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="account"
          :show-overflow-tooltip="true"
          label="登录账号"
          width="150"
        />
        <el-table-column
          prop="loginIp"
          label="登录IP"
          width="150"
        />
        <el-table-column
          label="备注"
        />
        <el-table-column
          prop="loginTime"
          label="登录时间"
          width="150"
        />
        <el-table-column
          width="80"
          label="状态"
        >
          <template slot-scope="{row}">
            <b-badge
                    class="d-flex justify-content-center"
                    style="align-items: center"
                    pill
                    :variant="statusColorObj[row.status]"
            >
              {{ statusObj[row.status] }}
            </b-badge>
          </template>
        </el-table-column>
      </el-table>
      <pagination
              v-show="total>0"
              :total="total"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.pageSize"
              @pagination="getList"
      />
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import pagination from '@core/components/pagination/Pagination'
import { loginPage } from '@/api/system/log/log'

export default {
  components: {
    pagination,
  },
  data() {
    return {
      loading: false,
      total: 0,
      statusObj: {
        1: '成功',
        2: '失败',
      },
      statusColorObj: {
        1: 'success',
        2: 'danger',
      },
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
      },
      tableData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      loginPage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>

<style scoped>

</style>
